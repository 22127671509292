<template>
	<div class="box box_mm">
		<div class="box_main">
			<search @search="search" @reset="reset" ref="mysearch" url="/api/proposa/index" :hiddentype="true" :hiddenbldw="true">
							
			</search>
			<div class="box_cont">
				<div class="btn_list">
					<!-- <el-button type="primary" @click="goaddnew">新增</el-button> -->
				</div>
				<div style="height: 76vh;box-sizing: border-box;">
					<MyTable ref="zjlist" :tableData="tableData" :tableHeader="tableHeader"
						:showbm="true" :czwidth="230" @getdata="gettable">
						<template slot="btns" slot-scope="{ scope }">
							<el-button @click="look(scope.row)" type="success" size="mini">详情</el-button>
							<el-button @click="lian(scope.row)" v-show="!scope.row.scyjlist" type="primary" size="mini"
								style="margin-left: 10px;">
								预审查意见
							</el-button>
							<el-button @click="edit(scope.row)" v-show="scope.row.scyjlist" type="warning" size="mini"
								style="margin-left: 10px;">
								编辑预审查意见
							</el-button>
							
						</template>
					</MyTable>
				</div>
			</div>
		</div>


		<!--详情-->
		<mydetail v-if="showdetail" :id="detail_id" :showdy="true" requsturl="/api/proposa/details"
			@closedetail="showdetail=false">
			<div slot="otherbtn" style="display: flex;flex-direction: column;">
				<el-button @click="lian(rowdata)" v-show="!rowdata.scyjlist" type="primary" size="small"
					style="margin-bottom:10px;margin-left: 10px;">
					预审查意见
				</el-button>
				<el-button @click="edit(rowdata)" v-show="rowdata.scyjlist" type="warning" size="small"
					style="margin-bottom:10px;margin-left: 10px;">
					编辑预审查意见
				</el-button>
			</div>

		</mydetail>

		<!--弹出-->
		<el-dialog :title="lianform.id?'编辑预审查意见':'预审查意见'" :visible.sync="lianshow" width="40%" :before-close="closelian">
			<div>
				<el-form ref="lianform" :model="lianform" :rules="lianrules" label-width="100px">
					<el-form-item label="建议" required>
						<el-radio-group v-model="lianform.type">
						    <el-radio label="建议立案">建议立案</el-radio>
						    <el-radio label="建议转意见">建议转意见</el-radio>
						    <el-radio label="建议不立案">建议不立案</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="预审查意见"  prop="scyj">
						<el-input  type="textarea" :rows="5"
							placeholder="请输入" v-model="lianform.scyj">
						</el-input>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="closelian">取 消</el-button>
				<el-button type="primary" @click="qrlian">确 定</el-button>
			</span>
		</el-dialog>



	</div>
</template>

<script>	
	import mydetail from "@/components/detail_tc.vue";
	import search from '../component/search';
	export default {
		components: {
			mydetail,
			search
		},
		data() {
			return {
				showdetail: false,
				detail_id: '',
				searchinput: '',
				tableHeader: [{
						name: '提案号',
						prop: 'code',
						width: '80'
					},
					{
						name:'流水号',
						prop:'lscode',
						width:'80'
					},
					{
						name: '标题',
						prop: 'name',
						width: '300'
					},
					{
						name: '提案类别',
						prop: 'lei_name',
						width: ''
					},
					{
						name: '是否为合并提案',
						prop: 'zid',
						width: '130',
						type: 'zid_tbn'
					},
					{
						name: '提交人',
						prop: 'user_name',
						width: ''
					},
					{
						name: '提交时间',
						prop: 'addtime',
						width: '150'
					},
					{
						name: '状态',
						prop: 'type',
						width: '120',
						type: 'tags'
					},
					{
						name: '处理异议',
						prop: 'type',
						width: '120',
						type: 'tags_yy'
					}
				],
				tableData: [],
				rowdata: '',
				lianshow: false,
				czid: '', //当前操作的数据id
				
				tcname:'',
				lianform: {
					id:'',
					proposa_id: '',
					scyj:'',
					type:'建议立案'
				},
				lianrules: {
					scyj:[{
						required: true,
						message: '请输入',
						trigger: 'change'
					}]
				},

			}
		},
		mounted() {
			this.$refs.mysearch.searyjstype='2'
			
			this.gettable()

		},
		activated() {
		    this.gettable(); // 每次进入页面时刷新数据
		},
		methods: {


			bulian(item) {
				this.$prompt('请输入不予立案的理由', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					inputPattern: /.+/,
					inputErrorMessage: '请输入'
				}).then(({
					value
				}) => {
					this.$post({
						url: '/api/proposa/byla',
						params: {
							id: item.id,
							bylaly: value
						}
					}).then((res) => {
						this.$message.success('操作成功')
						this.gettable()
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '取消操作'
					});
				})
			},



			//===========================立案
			closelian() {
				this.lianform = {
					id:'',
					proposa_id: '',
					scyj:'',
					type:'建议立案'
				}
				this.lianshow = false
			},
			qrlian() {
				this.$refs.lianform.validate((valid) => {
					if (valid) {						
						this.$post({
							url: this.lianform.id?'/api/proposa/xgcxyj':'/api/proposa/addcxyj',
							params: this.lianform
						}).then((res) => {
							this.$message.success('操作成功')
							this.closelian()
							this.gettable()
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			lian(item,ii) { //显示弹窗
				this.lianform.proposa_id = item.id
				this.lianshow = true
			},
			edit(item){
				this.isedit = true
				this.lianform = {
					id:item.scyjlist.id,
					proposa_id: item.id,
					scyj:item.scyjlist.scyj,
					type:item.scyjlist.type
				}
				this.lianshow = true
			},



			look(item) {
				this.rowdata = item
				this.detail_id = item.id
				this.showdetail = true
			},
			goaddnew() {
				this.$router.push('/system/add_zhengji')
			},
			addnew() {
				this.$router.push('/system/swiper_add')
			},
			reset() {
				this.$refs.mysearch.searchinput = ''
				this.$refs.mysearch.searchlei = ''
				this.$refs.mysearch.searchhuiyi = ''
				this.$refs.mysearch.searchhb = ''
				this.$refs.mysearch.searchstatusvalue = ''
				this.$refs.mysearch.searchstatus = ''
				this.$refs.mysearch.searyjstype = '2'
				this.$refs.mysearch.searchbldw = ''
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			search() {
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			gettable() {
				this.$post({
					url: '/api/proposa/index',
					params: {
						p: this.$refs.zjlist.inputpage,
						size: this.$refs.zjlist.pagesize,
						name: this.$refs.mysearch.searchinput,
						lei_id: this.$refs.mysearch.searchlei,
						sshy: this.$refs.mysearch.searchhuiyi,
						hidtype: this.$refs.mysearch.searchhb,
						blusers:this.$refs.mysearch.searchbldw,
						type:'',
						jstype:'2'
					}
				}).then((res) => {
					res.list.forEach((item,index)=>{
						if(item.jstype==1){
							item.type = 11 //待接收
						}
						if(item.jstype==3){
							item.type = 12 //退回
						}
						item.bltw = item.blusers_name+ ';' +item.fbusers_name
					})
					this.tableData = res.list
					this.$refs.zjlist.total = res.count

					//回显打勾
					let selectedlist = []
					res.list.forEach((item, index) => {
						if (item.xuan != 1) {
							selectedlist.push(item)
						}
					})
					this.$refs.zjlist.toggleSelection(selectedlist);
				})
			},
			deleterow(row) {
				this.$confirm('确认删除该条数据吗？')
					.then(_ => {
						this.$post({
							url: '/admin/banner/delete',
							params: {
								id: row.id
							}
						}).then((res) => {
							this.$message.success('删除成功')
							this.gettable()
						})
					})
					.catch(_ => {});

			},
			showsetrole(row) {
				console.log(row)
				this.$refs.mymx.showmx = true
			},
			changswitch(row) {
				console.log(row)
			}

		},
	}
</script>

<style lang="scss" scoped>
	.box_main {
		background-color: #fff;
		padding: 15px 25px;
		width: 100%;
		box-sizing: border-box;
		min-height: 100%;
	}

	.search_box {
		display: flex;
		align-items: center;
		font-size: 0.16rem;

		.search_item {
			display: flex;
			align-items: center;
			margin-right: 0.8rem;

		}
	}

	.box_cont {
		margin-top: 20px;
	}

	.btn_list {
		display: flex;
		align-items: center;
	}

	.tc_title {
		font-size: 0.18rem;
		font-weight: bold;
		color: #333;
		position: relative;
		padding-left: 20px;
		margin-bottom: 20px;
	}

	.tc_title:after {
		content: '';
		position: absolute;
		height: 100%;
		width: 4px;
		left: 0px;
		background-color: rgb(26, 144, 253);
	}

	::v-deep td {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell>.cell {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell.is-leaf {
		background-color: rgb(242, 242, 242);
		color: #777;
	}

	::v-deep .el-table th.el-table__cell.is-leaf,
	.el-table td.el-table__cell {}

	::v-deep .el-table td.el-table__cell {}

	::v-deep .el-table {
		margin-top: 10px;

	}

	::v-deep .el-table__fixed {}

	::v-deep .el-table::before {
		background-color: #fff !important;
	}

	::v-deep .el-table__fixed::before {
		background-color: #fff !important;
	}

	::v-deep .el-dialog__header {
		display: flex !important;
	}

	::v-deep .el-dialog__footer span {
		display: flex !important;
		justify-content: space-around;
		width: 100%;
	}
</style>